<template>
  <b-card class="statsClass">
    <b-skeleton-wrapper
      :loading="spinner"
      class="pt-2"
    >
      <template #loading>
        <b-skeleton width="100%" />
        <b-skeleton width="75%" />
        <b-skeleton width="50%" />
      </template>
      <div class="d-block font-weight-bolder text-nowrap stats">
        Stats
      </div>
      <div class="demo-vertical-spacing">
        <div class="statsdiv">
          <label class="labels">Profiles #:</label>
          <label class="float-right labels">{{ userStats.profileCount }}</label>
        <!-- <b-progress
          value="3"
          max="3"
          variant="success"
        /> -->
        </div>
        <div class="statsdiv">
          <label class="labels">Banner Ads #:</label>
          <label class="float-right labels"> {{ userStats.bannerCount }}</label>
        </div>
        <div class="statsdiv">
          <label class="labels">Devices #:</label>
          <label class="float-right labels">{{ userStats.deviceCount }}</label>
        </div>
      </div>
    </b-skeleton-wrapper>
  </b-card>
</template>
<script>
import {
  BCard, BSkeleton, BSkeletonWrapper,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BSkeleton,
    BSkeletonWrapper,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userStats: [],
      spinner: false,
    }
  },
  mounted() {
    this.getUserStats()
  },
  methods: {
    getUserStats() {
      this.spinner = true
      this.$axios.get(`admin/stats/${this.$route.params.userId}`)
        .then(({ data }) => {
          this.userStats = data.data
          this.spinner = false
        }).catch(() => this.$swal('UserStats not found!'))
    },
  },
}
</script>
<style scoped>
.stats{
  font-size: 14px;
  letter-spacing: 0px;
  color: #414141;
  font-weight: 600;
  font-family: "Montserrat";
}
.labels{
  font-size: 12px;
  letter-spacing: 0px;
  color: #414141;
  font-weight: 500;
  font-family: "Montserrat";
}
.statsdiv{
  margin-top: 19px;
  margin-bottom: 19px;
}
.statsClass{
  min-width: 309px;
  min-height: 177px;
  max-width: 309px;
  max-height: 177px;
}
</style>
