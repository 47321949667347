<template>
  <div>
    <b-row class="match-height">
      <b-col
        lg="8"
        md="6"
      >
        <user-detail />
      </b-col>
      <b-col
        lg="4"
        md="6"
      >
        <user-stats />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >
        <UserProfileCard />
      </b-col>
      <b-col
        lg="12"
        md="12"
      >
        <UserBannerAdsCard />
      </b-col>
      <b-col
        lg="12"
        md="12"
      >
        <UserMediaStorageCard />
      </b-col>
      <b-col
        lg="12"
        md="12"
      >
        <UserDevicesCard />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import UserDetail from '../../../components/admin/UserDetail.vue'
import UserStats from '../../../components/admin/UserStats.vue'
import UserProfileCard from '@/components/admin/UserProfileCard.vue'
import UserBannerAdsCard from '@/components/admin/UserBannerAdsCard.vue'
import UserMediaStorageCard from '@/components/admin/UserMediaStorageCard.vue'
import UserDevicesCard from '@/components/admin/UserDevicesCard.vue'

export default {
  components: {
    UserDevicesCard,
    UserMediaStorageCard,
    UserBannerAdsCard,
    UserProfileCard,
    BRow,
    BCol,
    UserDetail,
    UserStats,
  },
}
</script>
