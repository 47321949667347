<template>
  <div>
    <!-- profiles -->
    <div class="mb-1 d-block font-weight-bolder text-nowrap">
      Profiles
    </div>
    <b-card>
      <b-skeleton-wrapper
        :loading="isProfiles"
      >
        <template #loading>
          <b-skeleton width="100%" />
          <b-skeleton width="75%" />
          <b-skeleton width="50%" />
        </template>
        <b-row>
          <b-col
            v-for="profile in userProfiles"
            :key="profile.name"
            class="profile"
          >
            <span
              class="lead collapse-title"
            >
              <b-media class="media pl-2 pt-0 pb-0">
                <template
                  #aside
                  class="div"
                >
                  <b-avatar
                    size="32"
                    :src="profile.image"
                  />
                </template>
                <span class="d-block font-weight-bolder text-nowrap id">
                  {{ profile.name }}
                </span>
              </b-media>
            </span>
          </b-col>
        </b-row>
      </b-skeleton-wrapper>
    </b-card>
  </div>
</template>

<script>
import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
import Vue from 'vue'
import {
  BCard, BMedia,
  BAvatar,
  BSkeleton,
  BSkeletonWrapper,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import MediaType from '@/common/enums/mediaTypeEnum'

Vue.use(Vuesax)

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BSkeleton,
    BSkeletonWrapper,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      MediaType,
      userProfiles: [],
      isProfiles: false,
    }
  },
  async mounted() {
    await this.getUserProfiles()
  },
  methods: {
    async getUserProfiles() {
      this.isProfiles = true
      this.$axios
        .get(`admin/profile/${this.$route.params.userId}`)
        .then(({ data }) => {
          const { data: dataRes } = data || {}
          this.userProfiles = dataRes
          this.profileId = this.userProfiles.map(d => d.id)
          this.isProfiles = false
        }).catch(error => ({ error }))
    },
  },
}
</script>
<style scoped>
.profile{
  border: none;
  box-shadow: 0px 5px 11.5px rgba(0,0,0,0.08) !important;
  padding: 8px !important;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 6px;
  background-color: #ffffff;
}
.media{
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.id{
  font-size: 14px;
  letter-spacing: 0px;
  color: #414141;
  font-weight: 600;
  font-family: "Montserrat";
  padding-left: 15px;
}

</style>
