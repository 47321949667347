<template>
  <b-card class="card-congratulation-medal">
    <b-skeleton-wrapper
      :loading="spinner"
      class="pt-2"
    >
      <template #loading>
        <b-skeleton width="100%" />
        <b-skeleton width="75%" />
        <b-skeleton width="50%" />
      </template>
      <b-card-body class="pl-0">
        <div
          class="employee-task d-flex justify-content-left align-items-left"
        >
          <b-media no-body>
            <b-media-aside class="mr-75">
              <b-img
                :src="user.image? user.iamge: require('@/assets/images/simiicons/Group 13297.svg')"
                class="userImg"
              />
            </b-media-aside>
            <b-media-body class="my-auto">
              <div>
                <span class="font-weight-bold d-block text-nowrap id">
                  {{ user.name }}
                </span>
              </div>
              <small class="text-muted"> @{{ user.phone_number }}</small>
            </b-media-body>
          </b-media>
        </div>
      </b-card-body>
      <div class="flexs">
        <b-row>
          <b-col>
            <span class="id">#{{ user.id }}</span>
          </b-col>
          <b-col>
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(user.account_status)}`"
              class="badge"
            >
              {{ user.account_status === ActiveType.ACTIVE ? 'Active': 'Inactive' }}
            </b-badge>
          </b-col>
          <b-col class="emailDiv">
            <div class="text-truncate">
              <feather-icon
                icon="MailIcon"
                class="icon"
              />
              <span
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="user.email"
                class="mb-0 profileText"
              >
                {{ user.email }}
              </span>
            </div>
          </b-col>
          <b-col>
            <div class="float-right text-truncate">
              <feather-icon
                icon="PhoneIcon"
                class="icon"
              />
              <span class="mb-0 mr-1 profileText">
                {{ user.phone_number ? user.phone_number : 'N/A' }}
              </span>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-img
        :src="require('@/assets/images/simiicons/Intersection 1.png')"
        class="congratulation-medal"
        alt="Medal Pic"
      />
    </b-skeleton-wrapper>
  </b-card>
</template>

<script>
import {
  BCardBody,
  BCard, BImg, BRow, BCol, BMediaBody, BMediaAside, BMedia, BBadge, BSkeletonWrapper, BSkeleton, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ActiveType from '@/common/enums/accountStatusEnum'

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BMediaBody,
    BMediaAside,
    BMedia,
    BRow,
    BCol,
    BBadge,
    BSkeletonWrapper,
    BSkeleton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      ActiveType,
      user: [],
      spinner: false,
      Status: 'Active',
      employeeData: [
        {
          /* eslint-disable global-require */
          avatar: require('@/assets/images/simiicons/ImageIcon.svg'),
          userFullName: 'Simpsons',
          designation: '@image',
          duration: 'Default',
        },

      ],
    }
  },
  mounted() {
    this.getUser()
  },
  methods: {
    getUser() {
      this.spinner = true
      this.$axios.get(`admin/${this.$route.params.userId}`)
        .then(({ data }) => {
          this.user = data.data
          this.spinner = false
        }).catch(() => this.$swal('User not found!'))
    },
    resolveUserStatusVariant(Status) {
      if (Status === ActiveType.ACTIVE) return 'success'
      if (Status === ActiveType.INACTIVE) return 'secondary'
      return 'primary'
    },
  },
}
</script>
<style scoped>
.profileText{
  padding-left: 0px;
  font-size: 12px;
  line-height: 22px;
  color: #383838;
  font-weight: 400;
  font-family: "Montserrat";
}
.icon{
  width: 15px;
  height: 15px;
  color: black;
}
.id{
  font-size: 14px;
  letter-spacing: 0px;
  color: #383838;
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 16px;
 }
 .badge{
   font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  font-family: "Montserrat";
 }
 .col{
   padding-right: 0px !important;
 }
 .userImg{
   max-width: 70px;
   min-height: 70px;
   min-width: 70px;
   max-height: 70px;
   border-radius: 5px;
 }
.card-congratulation-medal{
  min-width: 647px;
  min-height: 177px;
  max-width: 647px;
  max-height: 177px;
}
.emailDiv{
  max-width: 150px
}
</style>
